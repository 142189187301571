import localforage from 'localforage';
import React from 'react';
import { tallyIdKey } from '~/constants/cache';

export default function useTallyId(initialValue?: string) {
  const [id, setId] = React.useState<string | undefined>(initialValue);

  React.useEffect(() => {
    const fetchId = async () => {
      const storedId = await localforage.getItem<string>(tallyIdKey);
      if (storedId) return setId(storedId);
      await localforage.setItem(tallyIdKey, initialValue);
      setId(initialValue);
    };

    fetchId();
  }, [initialValue]);

  return id;
}
